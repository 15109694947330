import React, { useEffect, useState } from 'react';
import { Box, Button, Card, Container, Flex, Heading, Section, Strong, Text, Tooltip } from '@radix-ui/themes';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import LocalSort from 'components/LocalSort';
import PageTitle from 'components/PageTitle';
import LocalMap from 'components/LocalMap';
import { MainSlider, PartnerSlider } from 'components/Slider';
import useFetch from 'hooks/useFetch';
import NoData from 'components/NoData';
import { local, localEngName } from 'constants';
/**
 * slice data code
 */
import { selectUserSelectedLocalCode, setUserSelectedLocal } from 'store/slices/common';

import './style.scss';

export default function Main() {
    //--- 지도영역 ---//
    const [selectedRegion, setSelectedRegion] = useState('전체');
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

    const dispatch = useDispatch();
    const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
    const baseURL = process.env.REACT_APP_API_BASE;

    /**
     * 교육 & 홍보 게시판 useFetch 함수
     */
    const { data: sliderList, fetchData: sliderFetchData } = useFetch(
        `${baseURL}/main/post?region=${userSelectedLocalCode}&month=${selectedMonth}`,
        {
            method: 'GET'
        }
    );

    /**
     * fetchListData useEffect 함수
     */
    useEffect(() => {
        // console.log('currentPage : ', currentPage)
        // console.log('fetchListData : ', fetchListData)
        const performFetch = async () => {
            // setIsLoading(true);
            await sliderFetchData();
            // setIsLoading(false);
        };
        performFetch().then();
    }, [userSelectedLocalCode]);

    /**
     * 공지사항 게시판 useFetch 함수
     */
    const { data: noticeList, fetchData: noticeFetchData } = useFetch(`${baseURL}/post/list`, {
        method: 'POST',
        body: {
            boardType: 'notice',
            region: 'ALL',
            searchType: '',
            search: '',
            pageNo: 1,
            pageSize: 3,
            inOrder: 'DESC'
        }
    });

    /**
     * 홍보 게시판 useFetch 함수
     */
    const { data: eventList, fetchData: eventFetchData } = useFetch(`${baseURL}/post/list`, {
        method: 'POST',
        body: {
            boardType: 'promotion',
            region: 'ALL',
            searchType: '',
            search: '',
            pageNo: 1,
            pageSize: 3,
            inOrder: 'DESC'
        }
    });

    /**
     * 초기 페이지 로딩 시 데이터 조회
     */
    useEffect(() => {
        sliderFetchData().then(() => {
            console.log('sliderList : ', sliderList);
        });
        noticeFetchData().then(() => {
            console.log('noticeList : ', noticeList);
        });
        eventFetchData().then(() => {
            console.log('eventList : ', eventList);
        });
    }, []);

    /**
     * selectedMonth 변경 시 데이터 조회
     */
    useEffect(() => {
        sliderFetchData().then(() => {
            console.log('sliderList : ', sliderList);
        });
    }, [selectedMonth]);

    /**
     * @description 지역센터소개 리스트 가져오기
     * @param region
     * @param e
     */
    const handleClick = (region, e) => {
        dispatch(setUserSelectedLocal(region));
        if (e.key === 'Enter' || e.type === 'click') {
            setSelectedRegion(region);
        }
    };

    return (
        <>
            <section className="visual main">
                <Container size="4">
                    <Heading as="h2" className="title2">
                        소프트웨어 인재양성을 이끄는
                    </Heading>
                    <div className="image-wrap">
                        <img src={require('assets/images/common/logo.svg').default} alt={'SW미래채움'} />
                    </div>
                </Container>
            </section>
            <Section p="0" className="main">
                <Container size="4">
                    <Flex align="center">
                        <LocalSort />
                        {/* <Box>
              <Flex align="center" className="month-sorting">
                <Button
                  radius="full"
                  className="prev"
                  onClick={() =>
                    setSelectedMonth((prev) => (prev > 1 ? prev - 1 : 12))
                  }
                >
                  이전
                </Button>
                <Strong className="title4">{selectedMonth}월</Strong>
                <Button
                  radius="full"
                  className="next"
                  onClick={() =>
                    setSelectedMonth((prev) => (prev < 12 ? prev + 1 : 1))
                  }
                >
                  다음
                </Button>
              </Flex>
            </Box> */}
                    </Flex>
                </Container>
                <Box className="slider-wrap">
                    {/* 메인 슬라이더 */}
                    {sliderList?.data?.posts.length > 0 ? <MainSlider sliderList={sliderList} /> : <NoData />}
                </Box>
                <Container size="4">
                    <Box mt="60px" mb="100px">
                        <Link to="/" title="어울림데이로 이동" className="slim-banner">
                            <img
                                src={require('assets/images/temp/@temp-1280x228.png')}
                                alt="정보통신산업진흥원과 함께한 2024 SW미래채움 어울림데이"
                            />
                        </Link>
                    </Box>
                    <Box mb="100px">
                        <PageTitle icon="star" title="홍보자료" />
                        <Flex justify="between">
                            {/* .chips.local에 지역명을 class로 입력시 해당 지역의 배지에 배경 색이 들어갑니다. 
                강원: gangwon, 경기: gyeonggi, 경남: gyeongnam, 경북: gyeongbuk, 광주: gwangju,
                대구: daegu, 울산: ulsan, 인천: incheon, 전남: jeonnam, 전북: jeonbuk,
                제주: jeju, 충남: chungnam , 충북: chungbuk
              */}
                            {eventList?.data?.posts.map((item, index) => (
                                <Box key={index}>
                                    <Link to={`/promotion/detail/${item.postNo}`} title={`${item.title}로 이동`}>
                                        <Card className="card image">
                                            <Box className="header image-wrap">
                                                {item.thumbnail && <img src={item.thumbnail} alt={item.title} />}
                                            </Box>
                                            <Box className="body">
                                                <Text
                                                    className={`chips local ${
                                                        localEngName[local.findIndex(local => local === item.region)]
                                                    }`}
                                                >
                                                    {item.region}
                                                </Text>
                                                <Text as="p" className="sub-title title-2line">
                                                    {item.title}
                                                </Text>
                                                <Flex justify="between" className="utility">
                                                    <Text className="body2">{new Date(item.createDate).toISOString().split('T')[0]}</Text>
                                                    <Text className="body2 view">{item.viewCount}</Text>
                                                </Flex>
                                            </Box>
                                        </Card>
                                    </Link>
                                </Box>
                            ))}
                        </Flex>
                    </Box>
                    <Box mb="100px">
                        <Flex justify="between">
                            <Box className="text-wrap">
                                <PageTitle icon="local" title="지역교육을 한눈에!" />
                                <Box className="card layer">
                                    <Box className="body">
                                        <Box className="image-wrap">
                                            <img src={require('assets/images/common/logo.svg').default} alt={'SW미래채움'} />
                                        </Box>
                                        <Box className="text-wrap">
                                            <Text as="p" className="title5">
                                                SW 미래채움 {selectedRegion}센터
                                            </Text>
                                            <Text as="p" className="body2">
                                                {selectedRegion}센터는 SW교육을 통해 지역의 SW 인재를 양성하고 있습니다.
                                            </Text>
                                        </Box>
                                    </Box>
                                    {selectedRegion !== '전체' && (
                                        <Box className="footer">
                                            <Link to={`/localOrganization`} title="조직도로 이동" className="sub-title">
                                                조직도
                                            </Link>
                                            <Link to={`/localMap`} title="찾아오시는 길로 이동" className="sub-title">
                                                찾아오시는 길
                                            </Link>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                            <Box className="map-wrap">
                                <LocalMap handleClick={handleClick} selectedRegion={selectedRegion} />
                            </Box>
                        </Flex>
                    </Box>
                    <Box mb="140px">
                        <Flex align="end">
                            <Box className="notice-wrap">
                                <PageTitle icon="bubble" title="공지사항" />
                                <Box className="notice">
                                    {noticeList?.data?.posts.map((item, index) => (
                                        <Link key={index} to={`/notice/detail/${item.postNo}`} title={item.title} className="body1 bold">
                                            <Text>
                                                [{item.region}] {item.title}
                                            </Text>
                                            <Text className="regular date">{new Date(item.createDate).toISOString().split('T')[0]}</Text>
                                        </Link>
                                    ))}
                                </Box>
                            </Box>
                            <Box className="buttons">
                                <Link to="/intro" title="사업소개로 이동" className="title5">
                                    사업 소개
                                    <span className="image-wrap">
                                        <img src={require('assets/images/common/img-book.svg').default} alt="사업 소개" />
                                    </span>
                                </Link>
                                <Link to="/ci" title="CI 소개로 이동" className="title5">
                                    CI 소개
                                    <span className="image-wrap">
                                        <img src={require('assets/images/common/logo-sw.svg').default} alt="CI 소개" />
                                    </span>
                                </Link>
                            </Box>
                        </Flex>
                    </Box>
                </Container>
            </Section>
            <Section p="0" className="partners">
                <Container size="4">
                    <Flex justify="between" align="center">
                        <Text as="p" className="sub-title">
                            함께하는 기관
                        </Text>
                        <PartnerSlider />
                    </Flex>
                </Container>
            </Section>
        </>
    );
}
