import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { instance } from "api/axios.instance";
import {
  Box,
  IconButton,
  Button,
  Flex,
  Heading,
  Separator,
  Text,
  VisuallyHidden,
  Link,
} from "@radix-ui/themes";
import TextUtils from "util/TextUtils";

import "./style.scss";

export default function BoardDetail({ boardType }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const [detailData, setDetailData] = useState({});
  const fetchPostDetail = useCallback(async () => {
    const response = await instance(`/post/detail/${id}`, {
      method: "GET",
    });
    setDetailData(response.data.data);
    console.log(response.data.data);
  }, [id]);

  useEffect(() => {
    fetchPostDetail();
  }, [fetchPostDetail]);

  const handleDownload = async (file) => {
    try {
      const response = await fetch(file.fileUrl);
      if (!response.ok) throw new Error("Network response was not ok");
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", file.originalName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Download error:", error);
    }
  };

  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <Box className="post-title">
        <Flex justify="center" align="center" gap="6">
          <Box
            className={`chips local ${TextUtils.convertRegionToEng(
              detailData.post?.region
            )}`}
          >
            <Text>{detailData.post?.region}</Text>
          </Box>
          <Heading as="h4" className="title3">
            {detailData.post?.title}
          </Heading>
        </Flex>
      </Box>
      <Box className="post-info">
        <Text as="p">조회수 : {detailData.post?.viewCount}</Text>
      </Box>
      <Box className="post-box">
        <Box className="post-content">
          <div dangerouslySetInnerHTML={{ __html: detailData.post?.content }} />
        </Box>
      </Box>
      <Box className="post-attachment">
        {detailData.files?.length > 0 && (
          <Flex mt="3">
            <Box>
              <Text
                as="p"
                weight="bold"
                className="attachment-title sub-title1 icon-clip"
              >
                첨부파일
              </Text>
            </Box>

            <ul className="attachment-list">
              {detailData.files.map((file, index) => (
                <li key={index}>
                  <Link href={file.fileUrl}>
                    <span className={`icon icon-${file.extension}`} />
                    <Text as="p">{file.originalName}</Text>
                  </Link>
                  <IconButton
                    onClick={() => handleDownload(file)}
                    className="rt-variant-text download"
                  >
                    <VisuallyHidden>다운로드 버튼</VisuallyHidden>
                  </IconButton>
                </li>
              ))}
            </ul>
          </Flex>
        )}
        {detailData.post?.link && (
          <Flex mt="3">
            <Box>
              <Text
                as="p"
                weight="bold"
                className="attachment-title sub-title1 icon-link"
              >
                관련링크
              </Text>
            </Box>
            <ul className="attachment-list">
              <li>
                <a
                  href={
                    detailData.post.link?.startsWith("http")
                      ? detailData?.post.link
                      : `https://${detailData.post?.link}`
                  }
                  target="_blank"
                  color="gray"
                >
                  <Text as="p" className="link">
                    {detailData.post.link?.startsWith("http")
                      ? detailData?.post.link
                      : `https://${detailData.post?.link}`}
                  </Text>
                </a>
              </li>
            </ul>
          </Flex>
        )}
      </Box>
      <Flex justify="end" mt="7">
        <Button className="register" onClick={goBack}>
          목록
        </Button>
      </Flex>
      <Box className="post-navigation">
        {detailData.post?.prevPost && (
          <Box className="item">
            <Text>이전글</Text>
            <Separator orientation="vertical" />
            <Text
              onClick={() =>
                navigate(
                  `/${boardType}/detail/${detailData.post?.prevPost?.postNo}`
                )
              }
              as="p"
            >
              {detailData.post?.prevPost?.title}
            </Text>
          </Box>
        )}
        {detailData.post?.nextPost && (
          <Box className="item">
            <Text>다음글</Text>
            <Separator orientation="vertical" />
            <Text
              onClick={() =>
                navigate(
                  `/${boardType}/detail/${detailData.post?.nextPost?.postNo}`
                )
              }
              as="p"
            >
              {detailData.post?.nextPost?.title}
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
}
