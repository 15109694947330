import React from "react";
import "@radix-ui/themes/styles.css";
import { Theme } from "@radix-ui/themes";

import ScrollToTop from "./hooks/useScrollTop";
/**
 * df-user token
 */
import "./assets/scss/tokens/df-user/token.scss";

import Header from "./components/Header";
import Footer from "./components/Footer";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RouteList from "./app/router";

function App() {
  return (
    <div className="App">
      <Theme accentColor={"blue"}>
        <Router>
          <Routes>
            {RouteList.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <>
                    <ScrollToTop>
                      {!route.noHeaderFooter && <Header />}
                      <main id="contents">{route.element}</main>
                      {!route.noHeaderFooter && <Footer />}
                    </ScrollToTop>
                  </>
                }
              />
            ))}
          </Routes>
        </Router>
      </Theme>
    </div>
  );
}

export default App;
