import Main from "../pages/Main";
import Intro from "../pages/Intro";
import CI from "../pages/CI";
import LocalCenter from "../pages/LocalCenterList";
import PromotionSystem from "pages/PromotionSystem";
import EduList from "./../pages/Edu";
import EduDetail from "./../pages/Edu/Detail";
import EventList from "../pages/Event";
import EventDetail from "../pages/Event/Detail";
import NotiList from "../pages/Noti";
import NotiDetail from "../pages/Noti/Detail";
import PromotionList from "../pages/Promotion";
import PromotionDetail from "../pages/Promotion/Detail";
import Privacy from "../pages/Privacy";
import Error404 from "components/Error/Error404";

const RouteList = [
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/intro",
    element: <Intro />,
  },
  {
    path: "/ci",
    element: <CI />,
  },
  {
    path: "/localcenterlist",
    element: <LocalCenter />,
  },
  {
    path: "/promotionSystem",
    element: <PromotionSystem />,
  },
  {
    path: "/localOrganization",
    element: <LocalCenter />,
  },
  {
    path: "/localMap",
    element: <LocalCenter />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/education",
    element: <EduList />,
  },
  {
    path: "/education/detail/:id",
    element: <EduDetail />,
  },
  {
    path: "/event",
    element: <EventList />,
  },
  {
    path: "/event/detail/:id",
    element: <EventDetail />,
  },
  {
    path: "/notice",
    element: <NotiList />,
  },
  {
    path: "/notice/detail/:id",
    element: <NotiDetail />,
  },
  {
    path: "/promotion",
    element: <PromotionList />,
  },
  {
    path: "/promotion/detail/:id",
    element: <PromotionDetail />,
  },
  {
    path: "*",
    element: <Error404 />,
    noHeaderFooter: true,
  },
];

export default RouteList;
