import React from "react";
import './style.scss'
import {Container} from "@radix-ui/themes";

export default function Visual(props) {
    return (
        <section className={`visual ${props.category}`}>
            <Container size="4">
                <h2 className="title2">{props.title}</h2>
            </Container>
        </section>
    )
}