export const local = [
  "전체",
  "강원",
  "경기",
  "경남",
  "경북",
  "광주",
  "대구",
  "울산",
  "인천",
  "전남",
  "전북",
  "제주",
  "충남",
  "충북",
];
export const localCode = [
  "ALL",
  "GW",
  "GG",
  "GN",
  "GB",
  "GJ",
  "DG",
  "US",
  "IC",
  "JN",
  "JB",
  "JJ",
  "CN",
  "CB",
];
export const localEngName = [
  "all",
  "gangwon",
  "gyeonggi",
  "gyeongnam",
  "gyeongbuk",
  "gwangju",
  "daegu",
  "ulsan",
  "incheon",
  "jeonnam",
  "jeonbuk",
  "jeju",
  "chungnam",
  "chungbuk",
];
export const sitemap = [
  {
    depth1: { name: "소개", url: "/intro" },
    depth2: [
      { name: "사업소개", url: "/intro" },
      { name: "사업 추진체계", url: "/promotionSystem" },
      { name: "지역센터소개", url: "/localcenterlist" },
      { name: "사업 CI", url: "/ci" },
      { name: "조직도", url: "/localOrganization", hide: true },
      { name: "찾아오시는 길", url: "/localMap", hide: true },
    ],
  },
  {
    depth1: { name: "교육안내", url: "/education" },
    depth2: [{ name: "교육정보", url: "/education" }],
  },
  {
    depth1: { name: "행사안내", url: "/event" },
    depth2: [{ name: "행사정보", url: "/event" }],
  },
  {
    depth1: { name: "공지사항", url: "/notice" },
    depth2: [{ name: "공지사항", url: "/notice" }],
  },
  {
    depth1: { name: "홍보자료", url: "/promotion" },
    depth2: [{ name: "홍보자료", url: "/promotion" }],
  },
];
