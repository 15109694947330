import React, { useEffect, useState } from 'react';
import { Box, Card, Container, Section, TabNav, Flex } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import Visual from 'components/Visual';
import Breadcrumbs from 'components/Breadcrumbs';
import PageTitle from 'components/PageTitle';
import LocalSort from 'components/LocalSort';
import CenterIntroduction from './CenterIntroduction';
import OrganizationChart from './OrganizationChart';
import MapDirections from './MapDirections';
import { Link } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import './style.scss';

/**
 * 로컬 정보 가져오기
 */
import { useSelector } from 'react-redux';
import { selectUserSelectedLocal, selectUserSelectedLocalCode } from 'store/slices/common';
import NoData from '../../components/NoData';

export default function LocalCenter() {
    const navigate = useNavigate();
    const [isLocalSelect, setIsLocalSelect] = useState(false);
    /**
     * SelectedLocal code
     */
    const userSelectedLocal = useSelector(selectUserSelectedLocal);
    const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);

    const pathname = window.location.pathname.toLowerCase();
    const baseURL = process.env.REACT_APP_API_BASE;
    /**
     * useFetch
     */
    const { data: localData, fetchData, error = null } = useFetch(`${baseURL}/center/${userSelectedLocalCode}`);

    const performFetch = async () => {
        await fetchData();
    };

    useEffect(() => {
        if (userSelectedLocalCode !== 'ALL') {
            setIsLocalSelect(true);
            performFetch().then();
        } else {
            setIsLocalSelect(false);
        }
    }, [userSelectedLocalCode]);
    /**
     * 초기 페이지 로딩 시 데이터 조회
     */
    useEffect(() => {
        if (isLocalSelect) {
            performFetch().then();
        }
    }, []);

    return (
        <>
            <Visual category="introduction" title="소개" />
            <Breadcrumbs category="introduction" />
            <Section p="0" mb={'var(--space-35)'} className="local-center">
                <Container>
                    <PageTitle icon="local" title="지역센터소개" />
                    <Box mb="120px">
                        <Card variant="classic">
                            <Box py="8px" px="40px">
                                <LocalSort hideAll={true} />
                            </Box>
                        </Card>
                    </Box>

                    {!isLocalSelect || error ? (
                        <NoData />
                    ) : (
                        <>
                            <Flex align="center" justify="between" mb="var(--space-15)">
                                <Box className="center-logo">
                                    <img
                                        src={require(`assets/images/logo/logo-${userSelectedLocalCode}.svg`)}
                                        alt={`${userSelectedLocal}센터 sw미래채움 로고`}
                                    />
                                </Box>
                                <Box>
                                    <img src={require('assets/images/icon/icon-school-2.svg').default} alt="SW미래채움 센터 아이콘" />
                                </Box>
                            </Flex>

                            <TabNav.Root className="tabslist">
                                <TabNav.Link asChild active={pathname === '/localcenterlist'}>
                                    <Link to="/localcenterlist" className="body1">
                                        센터소개
                                    </Link>
                                </TabNav.Link>
                                <TabNav.Link asChild active={pathname === '/localorganization'}>
                                    <Link to="/localOrganization" className="body1">
                                        조직도
                                    </Link>
                                </TabNav.Link>
                                <TabNav.Link asChild active={pathname === '/localmap'}>
                                    <Link to="/localMap" className="body1">
                                        찾아오시는 길
                                    </Link>
                                </TabNav.Link>
                            </TabNav.Root>

                            {pathname === '/localcenterlist' && <CenterIntroduction localData={localData?.data.info} />}
                            {pathname === '/localorganization' && (
                                <OrganizationChart
                                    localData={localData?.data.organization}
                                    localSelect={userSelectedLocal}
                                    localSelectCode={userSelectedLocalCode}
                                />
                            )}
                            {pathname === '/localmap' && <MapDirections localData={localData?.data.map} />}
                        </>
                    )}
                </Container>
            </Section>
        </>
    );
}
