import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, Strong, Text } from "@radix-ui/themes";
import LocalSort from "components/LocalSort";
import BoardSearchBox from "components/BoardSearchBox";
import BoardImageItems from "components/BoardImageItems";
import Pagination from "components/Pagination";
import NoData from "components/NoData";
const PAGE_SIZE = 8;

export default function BoardImage({
  localFilter,
  itemType,
  tableBody,
  setFetchBody,
  loading,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNo) => {
    const totalPages = tableBody?.pagination.totalPage;
    if (pageNo >= 1 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
      setFetchBody((prevData) => ({
        ...prevData,
        pageNo,
      }));
    }
  };
  const handleCellClick = (code) => {
    navigate(`${location.pathname}/detail/${code}`);
  };
  const getSearchText = ({ type, text }) => {
    setFetchBody((prevData) => ({
      ...prevData,
      searchType: type,
      search: text,
    }));
  };
  return (
    <>
      {localFilter && <LocalSort />}
      <Box className="board-list">
        <Flex
          justify="between"
          align="center"
          mt="var(--space-12)"
          height="48px"
        >
          <Text className="board-counter">
            Total :
            <Strong>
              &nbsp;{tableBody?.pagination.totalCount?.toLocaleString()}
            </Strong>
            건
          </Text>
          <Flex
            justify="end"
            align="center"
            gap="2"
            className="board-search-box"
          >
            <BoardSearchBox getSearchText={getSearchText} />
          </Flex>
        </Flex>
        {loading ? null : tableBody?.pagination?.totalCount > 0 ? (
          <>
            <Box mt="4">
              <BoardImageItems
                itemType={itemType}
                tableData={tableBody.list}
                onCellClick={handleCellClick}
              />
            </Box>
            <Flex justify="center" mt="var(--space-12)">
              <Pagination
                totalPosts={tableBody?.pagination.totalCount}
                postsPerPage={PAGE_SIZE}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            </Flex>
          </>
        ) : (
          <NoData />
        )}
      </Box>
    </>
  );
}
