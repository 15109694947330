class TextUtils {
  //['전체', '강원', '경기', '경남', '경북', '광주', '대구', '울산', '인천', '전남', '전북', '제주', '충남', '충북'] 영어로 변경하는 함수
  static convertRegionToEng(region) {
    switch (region) {
      case '전체':
        return 'all';
      case '강원':
        return 'gangwon';
      case '경기':
        return 'gyeonggi';
      case '경남':
        return 'gyeongnam';
      case '경북':
        return 'gyeongbuk';
      case '광주':
        return 'gwangju';
      case '대구':
        return 'daegu';
      case '울산':
        return 'ulsan';
      case '인천':
        return 'incheon';
      case '전남':
        return 'jeonnam';
      case '전북':
        return 'jeonbuk';
      case '제주':
        return 'jeju';
      case '충남':
        return 'chungnam';
      case '충북':
        return 'chungbuk';
      default:
        return 'all';
    }
  }


}

export default TextUtils;
