import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Flex } from "@radix-ui/themes";

import "./style.scss";
import { selectUserSelectedLocal, setUserSelectedLocal } from "store/slices/common";
import { local } from "constants";

export default function LocalSort({ hideAll }) {
  const dispatch = useDispatch();
  const userSelectedLocal = useSelector(selectUserSelectedLocal);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    setActiveIndex(local.indexOf(userSelectedLocal));
  }, []);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
    dispatch(setUserSelectedLocal(local[index]));
  };

  return (
    <>
      <Flex justify="between" className="local-sort">
        {local.map((item, index) => {
          if (hideAll && item === "전체") {
            return null;
          }
          return (
            <Button
              key={index}
              radius="full"
              className={"body1 " + (activeIndex === index ? "active" : "")}
              onClick={() => handleButtonClick(index)}
            >
              {item}
            </Button>
          );
        })}
      </Flex>
    </>
  );
}
