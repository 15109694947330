import React, { Fragment } from 'react';
import Visual from 'components/Visual';
import { Box, Container, Flex, Grid, Section, Text } from '@radix-ui/themes';
import Breadcrumbs from 'components/Breadcrumbs';
import PageTitle from 'components/PageTitle';
import './style.scss';

export default function Intro() {
    /**
    SW미래채움 주요성과 데이터 
   */
    const keyDataList = [
        {
            gridHeader: [
                {
                    icon: false,
                    alt: '',
                    title: '2019~2023'
                },
                {
                    icon: require('assets/images/icon/icon-sw-center.svg').default,
                    alt: 'SW미래채움 주요성과',
                    title: 'SW미래채움 센터구축'
                },
                {
                    icon: require('assets/images/icon/icon-sw-teacher.svg').default,
                    alt: 'SW전문강사 양성',
                    title: 'SW전문강사 양성'
                },
                {
                    icon: require('assets/images/icon/icon-sw-edu.svg').default,
                    alt: '초·중등학생 교육 운영',
                    title: '초·중등학생 교육 운영'
                },
                {
                    icon: require('assets/images/icon/icon-sw-purpose.svg').default,
                    alt: '주요 추진사항',
                    title: '주요 추진사항'
                }
            ],
            gridBody: [
                {
                    year: '2019',
                    local_num: 5,
                    local_new_list: '인천, 강원, 경남, 충북, 전남',
                    local_prev_list: '',
                    student: '504',
                    person: '22,322',
                    edu: '18,617',
                    percent: '83.4%',
                    history: ['SW미래채움 5개 센터 구축', '제1회 강사 네트워킹 데이 개최', 'SW강사 수업과정안 공모전 개최']
                },
                {
                    year: '2020',
                    local_num: 10,
                    local_new_list: '경기, 충남, 제주, 경북, 울산',
                    local_prev_list: '인천, 강원, 경남, 충북, 전남',
                    student: '1,026',
                    person: '39,480',
                    edu: '34,096',
                    percent: '86.4%',
                    history: [
                        'SW미래채움 5개 센터 추가 구축',
                        '제2회 강사 네트워킹 데이 개최',
                        'SW강사 수업과정안 공모전 개최',
                        'SW교육 커리큘럼 가이드라인 개발',
                        '고등부 글로벌 AI교육프로그램 운영 (인공지능 자율주행 과정)'
                    ]
                },
                {
                    year: '2021',
                    local_num: 10,
                    local_new_list: '',
                    local_prev_list: '인천, 강원, 경남, 충북, 전남, 경기, 충남, 제주, 경북, 울산',
                    student: '824',
                    person: '110,991',
                    edu: '94,191',
                    percent: '84.9%',
                    history: [
                        'SW미래채움 10개 센터 운영',
                        '제3회 강사 네트워킹 데이 개최',
                        'SW강사 수업과정안 공모전 개최',
                        '초·중학생 대상 코딩프로젝트 챌린지 개최',
                        '고등부 글로벌 AI교육프로그램 운영(인공지능 자율주행, 데이터 사이언스 2개과정)'
                    ]
                },
                {
                    year: '2022',
                    local_num: 11,
                    local_new_list: '대구',
                    local_prev_list: '인천, 강원, 경남, 충북, 전남, 경기, 충남, 제주, 경북, 울산',
                    student: '922',
                    person: '156,207',
                    edu: '127,246',
                    percent: '81.5%',
                    history: [
                        'SW미래채움 1개 센터 추가 운영',
                        '제4회 강사 네트워킹 데이 개최',
                        'SW강사 수업과정안 공모전 개최',
                        '초·중학생 대상 코딩프로젝트 챌린지 개최',
                        '고등부 글로벌 AI교육프로그램 운영',
                        'SW강사 민간자격증 도입'
                    ]
                },
                {
                    year: '2023',
                    local_num: 13,
                    local_new_list: '광주, 전북',
                    local_prev_list: '인천, 강원, 경남, 충북, 전남, 경기, 충남, 제주, 경북, 울산, 대구',
                    student: '1,181',
                    person: '253,742',
                    edu: '213,847',
                    percent: '84.3%',
                    history: [
                        'SW미래채움 2개 센터 추가 운영',
                        '제5회 강사 네트워킹 데이 개최',
                        'SW강사 수업과정안 공모전 개최',
                        '초·중학생 대상 코딩프로젝트 챌린지 개최',
                        '고등부 글로벌 코딩챌린지 개최',
                        'SW미래채움 역량강화 교육 추진',
                        'SW미래채움 늘봄학교 협력'
                    ]
                }
            ]
        }
    ];

    return (
        <>
            <Visual category="introduction" title="소개" />
            <Breadcrumbs category="introduction" />
            <Section p="0" mb={'var(--space-35)'} className="introduction">
                <Container size="4">
                    <PageTitle icon="school" title="사업소개" />

                    {/*페이지 정보*/}

                    <Box mb="var(--space-10)">
                        <h4 className="title5">사업목적</h4>
                        <p className="body2-description">
                            <span className="font-bold">지역 내 SW교육 접근성을 확보</span>
                            하고, 초·중등교육 단계에서부터의 체계적인 디지털 교육을 추진하여{' '}
                            <span className="font-bold">디지털 소양을 갖춘 지역 인재 양성 기반 마련</span>
                        </p>
                    </Box>

                    <Box mb="var(--space-20)">
                        <Flex justify="between">
                            <Flex direction="column" className="roundbox">
                                <div className="image-wrap">
                                    <img src={require('assets/images/pages/intro/intro-title-1.png')} alt="SW미래채움 센터구축" />
                                </div>
                                <div className="text-wrap outlined">
                                    <strong className="sub-title">SW미래채움센터 운영</strong>
                                    <ul className="dots">
                                        <li className="body1">체험존</li>
                                        <li className="body1">교육장</li>
                                        <li className="body1">교구재</li>
                                    </ul>
                                </div>
                            </Flex>
                            <Flex direction="column" className="roundbox">
                                <div className="image-wrap">
                                    <img src={require('assets/images/pages/intro/intro-title-2.png')} alt="SW전문강사 양성" />
                                </div>
                                <div className="text-wrap outlined">
                                    <strong className="sub-title">SW전문강사 양성</strong>
                                    <ul className="dots">
                                        <li className="body1">체계적인 SW교육을 통해 수준별 SW강사 양성 및 10개월 경력개발 지원</li>
                                        <li className="body1">
                                            지역 내 다양한 교육 수요 대응을 위한 역량강화 교육 및 활동 지원(자격증 등)
                                        </li>
                                    </ul>
                                </div>
                            </Flex>
                            <Flex direction="column" className="roundbox">
                                <div className="image-wrap">
                                    <img src={require('assets/images/pages/intro/intro-title-3.png')} alt="체계적 SW교육운영" />
                                </div>
                                <div className="text-wrap outlined">
                                    <strong className="sub-title">초·중등학생 대상 SW 교육 운영</strong>
                                    <ul className="dots">
                                        <li className="body1">
                                            센터 교육
                                            <br />- 최대 170시간 이상의 단계별 SW·AI 교육
                                            <br />- AI, 메타버스, IoT, 드론 등 최신 기술 체험
                                        </li>
                                        <li className="body1">
                                            찾아가는 교육
                                            <br />- 지역 내 교육수요를 기반으로 찾아가는 교육 추진
                                            <br />- 지역 초·중등학교/도서벽지/특수학교/지역아동센터 등
                                        </li>
                                    </ul>
                                </div>
                            </Flex>
                        </Flex>
                    </Box>

                    <Box mb="var(--space-20)">
                        <Text as="p" mb="var(--space-10)" className="title5">
                            SW미래채움 주요성과
                        </Text>
                        <Box pt="var(--space-4)" className="performance-wrap">
                            {keyDataList.map(({ gridHeader, gridBody }, idx) => (
                                <Grid columns="repeat(5, minmax(132px, auto))" gapX="var(--space-2)" key={idx}>
                                    {gridHeader.map((item, i) => (
                                        <Flex
                                            key={i}
                                            align="center"
                                            justify={item.icon ? 'start' : 'center'}
                                            gap="var(--space-4)"
                                            className="performace-item-head"
                                        >
                                            {item.icon && <img src={item.icon} alt={item.alt} />}
                                            <Text className="body1 font-bold">{item.title}</Text>
                                        </Flex>
                                    ))}
                                    {gridBody.map((el, i) => (
                                        <Fragment key={i}>
                                            <Flex align="center" justify="center" className="performace-item-body">
                                                <Text className="title5 year">{el.year}</Text>
                                            </Flex>

                                            <Flex
                                                direction="column"
                                                align="center"
                                                justify="center"
                                                gap="var(--space-4)"
                                                className="performace-item-body"
                                            >
                                                <Text className="title5 local">{el.local_num}개 지역</Text>
                                                <ul className="dots">
                                                    {el.local_prev_list && (
                                                        <li className="body2 dots-bg-red">
                                                            <span className="body2 font-bold">(계속)</span> {el.local_prev_list}
                                                        </li>
                                                    )}
                                                    {el.local_new_list && (
                                                        <li className="body2 dots-bg-red">
                                                            <span className="body2 font-bold">(신규)</span> {el.local_new_list}
                                                        </li>
                                                    )}
                                                </ul>
                                            </Flex>

                                            <Flex align="center" justify="center" className="performace-item-body">
                                                <Text className="title5 edu">{el.student}명 양성</Text>
                                            </Flex>

                                            <Flex
                                                direction="column"
                                                align="center"
                                                justify="center"
                                                gap="var(--space-4)"
                                                className="performace-item-body"
                                            >
                                                <Text className="title5 person">{el.person}명</Text>
                                                <ul className="dots">
                                                    <li className="body2 dots-bg-green">
                                                        찾아가는 교육 : {el.edu}명({el.percent})
                                                    </li>
                                                </ul>
                                            </Flex>

                                            <Flex
                                                direction="column"
                                                align="center"
                                                justify="center"
                                                gap="var(--space-4)"
                                                className="performace-item-body"
                                            >
                                                <ul className="dots">
                                                    {el.history.map((li, i) => (
                                                        <li key={i} className="body2 dots-bg-blue">
                                                            {li}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Flex>
                                        </Fragment>
                                    ))}
                                </Grid>
                            ))}
                        </Box>
                    </Box>

                    <Box mb="var(--space-20)">
                        <Text as="p" mb="var(--space-10)" className="title5">
                            주요 성과내 주요 추진사항
                        </Text>
                        <Flex justify="between" gap="var(--space-15)" className="promotion-wrap">
                            <Box className="promotion-item">
                                <Box className="icon-wrap">
                                    <img src={require('assets/images/pages/intro/intro-icon-1.svg').default} alt="지역확대" />
                                </Box>
                                <strong className="sub-title">지역확대</strong>
                                <Text as="p" mb="var(--space-6)" className="body2">
                                    총 13개 지역 내 SW미래채움센터 구축·운영을 통한 전 지역 빈틈없는 SW교육 추진
                                </Text>
                                <Text as="span" className="body3">
                                    * 강원, 경남, 인천, 전남, 충북, 경기, 경북, 울산, 충남, 제주, 대구, 광주, 전북
                                </Text>
                            </Box>

                            <Box className="promotion-item">
                                <Box className="icon-wrap">
                                    <img src={require('assets/images/pages/intro/intro-icon-2.svg').default} alt="SW교육 운영" />
                                </Box>
                                <strong className="sub-title">SW교육 운영</strong>
                                <ul className="dots">
                                    <li className="body2 dots-bg-blue">(초중) 코딩프로젝트 챌린지</li>
                                    <li className="body2 dots-bg-blue">(고등) 글로벌 AI·SW교육 프로그램</li>
                                </ul>
                            </Box>

                            <Box className="promotion-item">
                                <Box className="icon-wrap">
                                    <img src={require('assets/images/pages/intro/intro-icon-3.svg').default} alt="강사양성" />
                                </Box>
                                <strong className="sub-title">강사양성</strong>
                                <ul className="dots">
                                    <li className="body2 dots-bg-blue">강사 수업과정안 공모전</li>
                                    <li className="body2 dots-bg-blue">강사 네트워킹데이 개최</li>
                                </ul>
                            </Box>
                        </Flex>
                    </Box>
                </Container>
            </Section>
        </>
    );
}
