import React, { useState, useRef, useEffect, useCallback } from "react";
import { Box, Flex, Heading, Text } from "@radix-ui/themes";
import NoData from "components/NoData";
import Map from "./Map";

const MapDirections = ({ localData }) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapDisplay, setMapDisplay] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [address, setAddress] = useState([]);
  const mapContainerRef = React.useMemo(() =>
    coordinates.map((item) => React.createRef())
  );

  const [latitude, setLatitude] = useState([]);
  const [longitude, setLongitude] = useState([]);

  // 카카오 지도 서비스 객체를 이용하여 주소 -> 좌표 변환
  const waitForMapLoad = (address, retries = 5) => {
    console.log("address: ", address);
    return new Promise((resolve, reject) => {
      if (window.kakao && window.kakao.maps && window.kakao.maps.services) {
        const geocoder = new window.kakao.maps.services.Geocoder();
        geocoder.addressSearch(address, (result, status) => {
          if (status === window.kakao.maps.services.Status.OK) {
            resolve({ lat: result[0].y, lng: result[0].x });
          } else {
            reject("주소 검색 실패");
          }
        });
      } else if (retries > 0) {
        setTimeout(() => {
          waitForMapLoad(address, retries - 1)
            .then(resolve)
            .catch(reject);
        }, 1000);
      } else {
        reject("카카오 맵 서비스 로드 실패: 최대 재시도 횟수 초과");
      }
    });
  };

  // 카카오 맵 스크립트 로드
  const loadKakaoMapScript = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (document.querySelector("script[src^='//dapi.kakao.com']")) {
        resolve();
      } else {
        const mapScript = document.createElement("script");
        mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAOMAP_APPKEY}&libraries=services&autoload=false`;
        mapScript.onload = () =>
          window.kakao.maps.load(() => {
            // 카카오 맵 객체가 로드된 후, `kakao.maps.services`가 있는지 확인
            if (window.kakao.maps.services) {
              resolve(); // 맵 서비스 라이브러리가 로드되었으면 resolve 호출
            } else {
              reject("카카오 서비스 라이브러리 로드 실패");
            }
          });
        mapScript.onerror = () => reject("Kakao Maps 스크립트 로드 실패");
        document.head.appendChild(mapScript);

        console.log("loadKakaoMapScript finished");
      }
    });
  }, []);

  // useEffect(() => {
  //   const mapScript = document.createElement("script");
  //   mapScript.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAOMAP_APPKEY}&libraries=services&autoload=false`;
  //   mapScript.onload = () => setIsMapLoaded(true);
  //   document.head.appendChild(mapScript);

  //   return () => {
  //     document.head.removeChild(mapScript);
  //   };
  // }, []);

  useEffect(() => {
    // 카카오 맵 스크립트 로드 완료 후 isMapLoaded 상태 업데이트
    loadKakaoMapScript()
      .then(() => {
        console.log("isMapLoaded: true");
        setIsMapLoaded(true);
      })
      .catch((error) => {
        console.error(error);
      });

    // return () => {
    //   const scripts = document.querySelectorAll(
    //     "script[src^='//dapi.kakao.com']"
    //   );
    //   scripts.forEach((script) => document.head.removeChild(script));
    // };
  }, [loadKakaoMapScript]);

  useEffect(() => {
    if (localData) {
      const addresses = localData.map((item) => item.addressMap);
      setAddress(addresses);
    }
  }, [localData]);

  useEffect(() => {
    if (address.length > 0 && isMapLoaded) {
      Promise.all(
        address.map((addr) =>
          waitForMapLoad(addr)
            .then((coords) => coords)
            .catch((error) => {
              console.error(`주소(${addr}) 좌표 변환 실패: ${error}`);
              return null; // 실패한 주소는 null로 처리
            })
        )
      ).then((coordsArray) => {
        const validCoordinates = coordsArray.filter(
          (coords) => coords !== null
        );
        console.log("isMapLoaded: ", isMapLoaded);
        setCoordinates((prevCoords) => {
          // 이전 상태와 새로운 좌표를 병합하여 업데이트
          const newCoords = [...prevCoords];
          validCoordinates.forEach((coord, index) => {
            newCoords[index] = coord;
          });
          return newCoords;
        });
        console.log("validCoordinates: ", validCoordinates);
      });
    }
  }, [address, isMapLoaded]);

  return localData ? (
    <>
      {localData.map((item, index) => (
        <Box mt="48px" className="map-wrap no-border" key={index}>
          <Flex align="center">
            <Heading as="h5" className="title3">
              {item.centerName}
            </Heading>
            <Text as="p" className="body3 extra">
              {item.caution}
            </Text>
          </Flex>
          <Box mt="40px" mb="60px" className="map">
            {/* 지도 표시 */}
            {isMapLoaded && coordinates[index] && (
              <Map
                ref={mapContainerRef[index]}
                latitude={coordinates[index].lat}
                longitude={coordinates[index].lng}
              />
            )}
          </Box>
          <Box mb="60px">
            <Text as="p" className="title4">
              주소
            </Text>
            <Text as="p" className="sub-title regular">
              {item.addressText}
            </Text>
          </Box>
          {item?.guidance?.map((item, index) => (
            <Box mb="60px" key={index}>
              <Text as="p" className={`title4 icon ${item.category}`}>
                {item.title}
              </Text>
              {item?.ways?.map((way, index) => (
                <Text as="p" className="sub-title regular" key={index}>
                  <b>{way.title}</b> : {way.content}
                </Text>
              ))}
            </Box>
          ))}
        </Box>
      ))}
    </>
  ) : (
    <NoData />
  );
};

export default MapDirections;
