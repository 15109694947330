import React from "react";
import { Box, Flex, Table, Heading, Text } from "@radix-ui/themes";
import NoData from "components/NoData";

const OrganizationChart = ({ localData, localSelect, localSelectCode }) => {
  return (
    <>
      <Box mt="var(--space-12)" mb="var(--space-15)" px="var(--space-10)">
        <Flex justify="center" className="section-inner">
          <div className="image-wrap organization">
            <img
              src={require(`assets/images/pages/organization/map-${localSelectCode}.png`)}
              alt={`${localSelect}센터 조직도`}
              longdesc="과학기술 정보통신부(사업기획 및 시행계획 수립, 신규과제 수행기관 확정), 정보통신 산업진흥원(사업 세부추진계획 수립, 신규 및 결과평가 계획(안) 수립·시행 - *외부 전문가로 구성된 평가위원회를 통해 평가 진행, 사업 진도 및 성과관리), 지방자치단체(지자체 內 SW교육환조성을 위한 지원(예산매칭 등)), 수행기관(정보소외지역SW교육지원강화사업(SW미래채움) 추진 - *SW교육 인프라 운영 및 관리 등, **지역 초‧중등학생 대상 방문SW교육(맞춤형‧찾아가는 SW교육 등) 수행, ***지역 인재(미취업청년, 경력단절여성, 퇴직전문가 등) 대상 SW전문강사 양성 및 채용연계 등)으로 구성됨"
            />
          </div>
        </Flex>
      </Box>

      {localData?.details ? (
        localData.details?.map((item, index) => (
          <Box pt="var(--space-15)" className="line table-wrap" key={index}>
            <Box mb="var(--space-6)">
              <Heading as="h5" className="title4">
                {item.orgName}
              </Heading>
              <Text as="p" className="sub-title">
                {item.orgInfo}
              </Text>
            </Box>
            <Table.Root layout="auto" className="data-table">
              <caption>센터 안내</caption>
              <Table.Header>
                <Table.Row>
                  <Table.ColumnHeaderCell
                    justify="center"
                    py="var(--space-5)"
                    className="sub-title"
                    width="100px"
                  >
                    직책
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell
                    justify="center"
                    py="var(--space-5)"
                    className="sub-title"
                    width="120px"
                  >
                    이름
                  </Table.ColumnHeaderCell>
                  <Table.ColumnHeaderCell
                    justify="center"
                    py="var(--space-5)"
                    className="sub-title"
                    width="200px"
                  >
                    전화번호
                  </Table.ColumnHeaderCell>
                  {/*<Table.ColumnHeaderCell*/}
                  {/*  justify="center"*/}
                  {/*  py="var(--space-5)"*/}
                  {/*  className="sub-title"*/}
                  {/*  width="200px"*/}
                  {/*>*/}
                  {/*  이메일*/}
                  {/*</Table.ColumnHeaderCell>*/}
                  <Table.ColumnHeaderCell
                    justify="center"
                    py="var(--space-5)"
                    className="sub-title"
                  >
                    업무내용
                  </Table.ColumnHeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {item.managers?.map((manager, index) => (
                  <Table.Row key={index}>
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.rank || "-"}
                    </Table.Cell>
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.name || "-"}
                    </Table.Cell>
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.phone || "-"}
                    </Table.Cell>
                    {/*<Table.Cell justify="center" py="22px" className="body1">*/}
                    {/*  {manager.email}*/}
                    {/*</Table.Cell>*/}
                    <Table.Cell justify="center" py="22px" className="body1">
                      {manager.duty}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table.Root>
          </Box>
        ))
      ) : (
        <NoData />
      )}
    </>
  );
};

export default OrganizationChart;
