import { useState } from "react";

const useFetch = (url, options = {}, timeout = 5000) => {
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { method = "GET", body = null } = options;

  const fetchData = async () => {
    setError(null);
    const controller = new AbortController();
    // const signal = controller.signal;

    // 타임아웃 설정
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    const headers = {
      accept: "application/json",
      ...(body instanceof FormData
        ? {}
        : { "Content-Type": "application/json" }),
    };

    try {
      setLoading(true);

      const fetchOptions = {
        method,
        headers: headers,
        ...options,
      };

      // console.log("body FormData 여부: ", body instanceof FormData);

      if (body instanceof FormData) {
        for (let [key, value] of body.entries()) {
          if (value instanceof File) {
            console.log(`Field: ${key}, File Name: ${value.name}`);
          } else {
            console.log(`Field: ${key}, Value: ${value}`);
          }
        }
      }

      if (body) {
        fetchOptions.body =
          body instanceof FormData ? body : JSON.stringify(body);
      }

      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      setData(result);
    } catch (err) {
      console.log("err: ", err);
      setLoading(false);
      setError(err);
    } finally {
      clearTimeout(timeoutId);
      setLoading(false);
    }
  };

  return { data, total, loading, error, fetchData };
};

export const useFormDataFetch = (url, options = {}, timeout = 5000) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { method = "GET", body = null } = options;

  console.log("useFormDataFetch options: ", options);

  const fetchFormData = async () => {
    // const controller = new AbortController();
    // const signal = controller.signal;

    // 타임아웃 설정
    // const timeoutId = setTimeout(() => controller.abort(), timeout);

    try {
      setLoading(true);

      const fetchOptions = {
        method,
        body,
        // signal,
      };

      const response = await fetch(url, fetchOptions);

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${errorText}`);
      }

      const result = await response.json();

      setData(result);
    } catch (err) {
      console.log("err: ", err);
      setError(err.message);
    } finally {
      // clearTimeout(timeoutId);
      setLoading(false);
    }
  };

  return { data, loading, error, fetchFormData };
};

export default useFetch;
