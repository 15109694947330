import React, { useState } from "react";
import { Container, Flex } from "@radix-ui/themes";
import { useLocation, Link } from "react-router-dom";
import { sitemap } from "constants";
import "./style.scss";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleMouseOver = () => {
    setIsMenuOpen(true);
  };

  const handleMouseOut = () => {
    setIsMenuOpen(false);
  };

  const isLinkActive = (link) => {
    const cleanedPath = currentPath.split("?")[0];

    if (link === "/localcenterlist") {
      return ["/localcenterlist", "/localOrganization", "/localMap"].includes(
        cleanedPath
      );
    }

    const regex = new RegExp(`^${link}(/|$)`);
    return regex.test(cleanedPath);
  };

  const isMenuActive = (depth1Url, depth2Urls) => {
    const cleanedPath = currentPath.split("?")[0];

    // depth1 URL이 현재 경로와 정확히 일치하거나 하위 경로인지 확인
    const isDepth1Active = new RegExp(`^${depth1Url}(/|$)`).test(cleanedPath);

    // depth2 URL 중 현재 경로와 정확히 일치하거나 하위 경로인 경우
    const isAnyDepth2Active = depth2Urls?.some((url) =>
      new RegExp(`^${url}(/|$)`).test(cleanedPath)
    );

    return isDepth1Active || isAnyDepth2Active;
  };

  return (
    <>
      <header>
        <Container size="4">
          <Flex align="center" className="container">
            <h1 className="logo">
              <Link to="/" title="메인으로 이동">
                SW미래채움
              </Link>
            </h1>
            <nav
              id="menu"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
            >
              {sitemap.map((item, index) => (
                <Link
                  to={item.depth1.url}
                  key={index}
                  className={`sub-title ${
                    isMenuActive(
                      item.depth1.url,
                      item.depth2.map((sub) => sub.url)
                    )
                      ? "active"
                      : ""
                  }`}
                  title={`${item.depth1.name}로 이동`}
                >
                  {item.depth1.name}
                </Link>
              ))}
            </nav>
          </Flex>
        </Container>

        <div
          className={`menu-wrap ${isMenuOpen ? "open" : ""}`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <Container size={4}>
            <Flex className="menu">
              {isMenuOpen &&
                sitemap.map((item, depth1Index) => (
                  <div key={depth1Index} className="depth-items">
                    {item.depth2
                      .filter((item) => !item.hide)
                      .map((subItem, depth2Index) => (
                        <Link
                          key={depth2Index}
                          to={subItem.url}
                          className={`sub-menu-item ${
                            isLinkActive(subItem.url) ? "active" : ""
                          }`}
                          title={`${subItem.name}로 이동`}
                        >
                          {subItem.name}
                        </Link>
                      ))}
                  </div>
                ))}
            </Flex>
          </Container>
        </div>
      </header>
    </>
  );
}
