import React, { useCallback, useState, useEffect } from "react";
import { Container, Section } from "@radix-ui/themes";
import Visual from "components/Visual";
import Breadcrumbs from "components/Breadcrumbs";
import PageTitle from "components/PageTitle";
import BoardImage from "template/BoardImage";
import { instance } from "api/axios.instance";
import { useSelector } from "react-redux";
import { selectUserSelectedLocalCode } from "store/slices/common";

export default function PromotionList() {
  const pageName = "교육정보";
  const pageCode = "edu";
  const boardType = "education";
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState({
    list: [],
    pagination: {},
  });
  const [fetchBody, setFetchBody] = useState({
    boardType,
    pageNo: 1,
    pageSize: 8,
    searchType: "",
    search: "",
    inOrder: "DESC",
    region: userSelectedLocalCode,
  });

  const fetchList = useCallback(async () => {
    const response = await instance("/post/list", {
      method: "POST",
      data: { ...fetchBody, region: userSelectedLocalCode },
    });
    setList({
      pagination: response.data.data.pagination,
      list: response.data.data.posts,
    });
    setLoading(false);
  }, [fetchBody, userSelectedLocalCode]);

  useEffect(() => {
    fetchList();
  }, [fetchBody, fetchList]);
  return (
    <>
      <Visual category={pageCode} title={pageName} />
      <Breadcrumbs category={pageCode} />
      <Section p="0" mb={"var(--space-35)"} className={pageCode}>
        <Container size="4">
          <PageTitle icon="star" title={pageName} />
          <BoardImage
            localFilter
            itemType="poster"
            tableBody={list}
            setFetchBody={setFetchBody}
            loading={loading}
          />
        </Container>
      </Section>
    </>
  );
}
