import React from "react";
import {Box, Card, Flex, Grid, Inset, Text} from "@radix-ui/themes";
import TextUtils from "util/TextUtils";

import "./style.scss";

export default function BoardImageItems({itemType, tableData, onCellClick}) {
  return (
    <Grid columns="4" className={`board-image-items type-${itemType}`}>
      {tableData.map((item, index) => {
        return (
          <Card
            className="board-card"
            key={index}
            onClick={() => onCellClick(item.postNo)}
          >
            {item.thumbnail === null ? (
              <Box className={`no-image type-${itemType}`}></Box>
            ) : (
              <Inset
                clip="padding-box"
                side={itemType === "list" ? "left" : "top"}
                className="image-box"
                style={{backgroundImage: `url(${item.thumbnail})`}}
              >
                <img src={item.thumbnail} alt={item.title}/>
              </Inset>
            )}
            <Box className="card-box">
              <Box className="board-extra">
                <Text
                  className={`chips local ${TextUtils.convertRegionToEng(
                    item.region
                  )}`}
                >
                  {item.region}
                </Text>
              </Box>
              <Box className="card-head">
                <Text as="p" className="sub-title ellipsis fix-2line">
                  {item.title}
                </Text>
              </Box>
              {/*{props.itemType !== "thumbnail" ? (*/}
              {/*  <Box className="card-body">*/}
              {/*    <Text className="icon-clip">파일명.zip</Text>*/}
              {/*  </Box>*/}
              {/*) : null}*/}
              <Box className="card-footer">
                <Flex align="center">
                  <Text>
                    {new Date(item.createDate).toISOString().split("T")[0]}
                  </Text>
                  <Box className="view">{item.viewCount}</Box>
                </Flex>
              </Box>
            </Box>
          </Card>
        );
      })}
    </Grid>
  );
}
