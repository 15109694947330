import React, { useEffect } from "react";

const Map = React.forwardRef(({ latitude, longitude }, ref) => {
  const defaultLatitude = 37.5665;
  const defaultLongitude = 126.978;

  useEffect(() => {
    // 카카오 맵이 정상적으로 로드되었는지 확인
    if (!window.kakao || !window.kakao.maps || !ref?.current) return;

    window.kakao.maps.load(() => {
      const container = ref.current;
      const mapLatitude = latitude || defaultLatitude;
      const mapLongitude = longitude || defaultLongitude;
      const options = {
        center: new window.kakao.maps.LatLng(mapLatitude, mapLongitude),
        level: 3, // 지도 확대 레벨 설정
      };

      // 지도 생성
      const map = new window.kakao.maps.Map(container, options);

      // 마커 위치 설정
      const markerPosition = new window.kakao.maps.LatLng(
        mapLatitude,
        mapLongitude
      );
      const marker = new window.kakao.maps.Marker({
        position: markerPosition,
      });

      // 지도에 마커 표시
      marker.setMap(map);
    });
  }, [latitude, longitude, ref]);

  return <div ref={ref} id="map" style={{ width: "100%", height: "400px" }} />;
});

export default Map;
