import React from "react";
import { Container, Section } from "@radix-ui/themes";
import Visual from "components/Visual";
import Breadcrumbs from "components/Breadcrumbs";
import PageTitle from "components/PageTitle";
import BoardDetail from "template/BoardDetail";
import { useParams } from "react-router-dom";

export default function NotiDetail() {
  const pageName = "공지사항";
  const pageCode = "noti";
  const { id } = useParams();
  const boardType = "notice";

  return (
    <>
      <Visual category={pageCode} title={pageName} />
      <Breadcrumbs category={pageCode} />
      <Section p="0" mb={"var(--space-35)"} className={pageCode}>
        <Container size="4">
          <PageTitle icon={pageCode} title={pageName} />
          <BoardDetail pageCode={pageCode} boardType={boardType} postId={id} />
        </Container>
      </Section>
    </>
  );
}
