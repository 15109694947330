import React, { useCallback, useState, useEffect } from "react";
import { Container, Section } from "@radix-ui/themes";
import Visual from "components/Visual";
import Breadcrumbs from "components/Breadcrumbs";
import PageTitle from "components/PageTitle";
import BoardList from "template/BoardList";
import { instance } from "api/axios.instance";
import { useSelector } from "react-redux";
import { selectUserSelectedLocalCode } from "store/slices/common";

export default function NotiList() {
  const pageName = "공지사항";
  const pageCode = "noti";
  const userSelectedLocalCode = useSelector(selectUserSelectedLocalCode);
  const [loading, setLoading] = useState(true);
  const [noticeList, setNoticeList] = useState({ list: [], pagination: {} });
  const [fetchBody, setFetchBody] = useState({
    boardType: "notice",
    pageNo: 1,
    pageSize: 8,
    searchType: "",
    search: "",
    inOrder: "DESC",
  });
  const tableHeader = [
    { label: "번호", key: "index", width: "10%" },
    { label: "지역", key: "region", width: "10%" },
    { label: "제목", key: "title", width: "auto", left: true },
    { label: "첨부파일", key: "isFile", width: "15%" },
    { label: "조회수", key: "viewCount", width: "10%" },
    { label: "등록일", key: "createDate", width: "12%" },
  ];

  const fetchNoticeList = useCallback(async () => {
    const response = await instance("/post/list", {
      method: "POST",
      data: { ...fetchBody, region: userSelectedLocalCode },
    });
    setNoticeList({
      pagination: response.data.data.pagination,
      list: response.data.data.posts,
    });
    setLoading(false);
  }, [fetchBody, userSelectedLocalCode]);

  useEffect(() => {
    fetchNoticeList();
  }, [fetchBody, fetchNoticeList]);
  return (
    <>
      <Visual category={pageCode} title={pageName} />
      <Breadcrumbs category={pageCode} />
      <Section p="0" mb={"var(--space-35)"} className={pageCode}>
        <Container size="4">
          <PageTitle icon={pageCode} title={pageName} />
          <BoardList
            localFilter
            tableHeader={tableHeader}
            tableBody={noticeList}
            setFetchBody={setFetchBody}
            loading={loading}
          />
        </Container>
      </Section>
    </>
  );
}
