import React from "react";
import { Container, Flex } from "@radix-ui/themes";
import { useLocation, Link } from "react-router-dom";
import { sitemap } from "constants";
import "./style.scss";

export default function Breadcrumbs() {
  const location = useLocation();
  const currentPath = location.pathname;
  const basePath = `/${currentPath.split("/")[1]}`;
  const breadcrumbData = sitemap.find((item) => {
    if (item.depth1.url === basePath) return true;
    return item.depth2.some((sub) => sub.url === basePath);
  });
  const depth2Match =
    breadcrumbData?.depth2?.find((sub) => sub.url === basePath) || null;

  return (
    <section>
      <Container size="4">
        <Flex className="breadcrumbs">
          <Link to="/" title="메인으로 이동" className="home">
            메인
          </Link>
          {breadcrumbData ? (
            <>
              <div className="button-wrap">
                <span className="body2">{breadcrumbData.depth1.name}</span>
                {depth2Match?.name && (
                  <span className="body2">{depth2Match.name}</span>
                )}
              </div>
            </>
          ) : (
            basePath === "/privacy" && (
              <div className="button-wrap">
                <span className="body2" style={{ width: "200px" }}>
                  개인정보처리방침
                </span>
              </div>
            )
          )}
        </Flex>
      </Container>
    </section>
  );
}
