import React from "react";
import { Box, Table, Theme } from "@radix-ui/themes";
import classNames from 'classnames';
import "./style.scss";

export const BoardTextItems = ({
  totalCount,
  currentPage,
  tableHeader,
  tableData,
  onCellClick,
}) => {
  const formatCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <Box className="board-text-items">
      <Theme radius="none">
        <Table.Root variant="surface" size="3">
          <caption>텍스트 게시판 리스트 타입</caption>
          <Table.Header>
            <Table.Row align="center">
              {tableHeader?.map((header, index) => (
                <Table.ColumnHeaderCell
                  key={index}
                  justify="center"
                  width={header.width}
                >
                  {header.label}
                </Table.ColumnHeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {tableData.map((post, rowIndex) => (
              <Table.Row key={rowIndex} align="center" className={classNames({ 'row-notice': post.isFixed })}>
                {tableHeader?.map((header, colIndex) => (
                  <Table.Cell
                    key={colIndex}
                    justify={header.left ? "start" : "center"}
         
                    data-title={header.label}
                    onClick={() => onCellClick(post.postNo)}
                  >
                   {header.key === "index" 
                      ? post.isFixed 
                      ?   <img src={require("assets/images/icon/icon-megaphone.svg").default} alt="공지사항"/>
                      :  Math.max(totalCount - ((currentPage - 1) * 10 + rowIndex),1) 
                      : null}
                    {/*첨부파일*/}
                    {header.key === "isFile" && post[header.key] === true && (
                      <img
                        src={
                          require("assets/images/icon/icon-clip.svg").default
                        }
                        alt="첨부파일"
                      />
                    )}
                    {/*기타등등*/}
                    {header.key === "viewCount"
                      ? formatCommas(post[header.key])
                      : header.key === "createDate" ||
                        header.key === "startDate" ||
                        header.key === "endDate"
                      ? new Date(post.createDate)?.toISOString().split("T")[0]
                      : post[header.key]}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Theme>
    </Box>
  );
};
