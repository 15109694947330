import React from 'react';
import { Box, Container, Flex, Section, Text, Button } from '@radix-ui/themes';
import Visual from 'components/Visual';
import Breadcrumbs from 'components/Breadcrumbs';
import PageTitle from 'components/PageTitle';

import './style.scss';

export default function CI() {
    return (
        <>
            <Visual category="introduction" title="소개" />
            <Breadcrumbs category="introduction" />
            <Section p="0" mb={'var(--space-35)'} className="business">
                <Container size="4">
                    <PageTitle icon="school" title="사업CI" />
                    <Box mb="var(--space-20)" className="ci-wrap">
                        <Flex align="center" direction="column">
                            <Box mb="24px" className="cover">
                                <img src={require('assets/images/pages/intro/ci-logo-line.svg').default} alt="SW미래채움" />
                            </Box>
                            <Text className="body1">
                                소프트웨어 미래채움 사업을 대표하는 교육 센터를 심볼로 활용하여 직관적으로 사업에 대하여 이해할 수 있도록
                                하였습니다.
                                <br />
                                또한 교육 센터의 형상과 사업의 핵심 가치인{' '}
                                <span>
                                    <strong className="title4">A</strong>ccessible
                                </span>
                                ,{' '}
                                <span>
                                    <strong className="title4">A</strong>uthentic
                                </span>
                                ,{' '}
                                <span>
                                    <strong className="title4">A</strong>cademic
                                </span>
                                의 이니셜인 A를 연상할 수 있도록 디자인 되었습니다.
                            </Text>
                        </Flex>
                    </Box>
                    <Box mb="var(--space-20)" className="rule">
                        <Box mb="40px">
                            <h4 className="title5">최소사용규정</h4>
                            <p className="body2-description">가독성을 고려해 가로 너비 24mm 이하로 사용할 수 없습니다.</p>
                        </Box>
                        <Flex justify="between">
                            <div>
                                <img src={require('assets/images/pages/intro/ci-logo-line.svg').default} alt="SW미래채움 최소사용규정" />
                            </div>
                            <div className="box-outline">
                                <img
                                    src={require('assets/images/pages/intro/ci-logo-border.svg').default}
                                    alt="SW미래채움 최소사용규정 24mm"
                                />
                            </div>
                        </Flex>
                    </Box>
                    <Box mb="var(--space-20)" className="color">
                        <Box mb="60px">
                            <h4 className="title5">대표색상</h4>
                            <p className="body2-description">
                                SW미래채움의 전용색상은 여러 매체에 사용되어 이미지 전달에 중요한 역할을 하는 요소이므로 정확한 색상, 몇도,
                                채도 등을 유지하여 사용하고 관리하여야 합니다.
                                <br />
                                색상은 별색 사용을 원칙으로 하지만 부득이한 경우에는 4원색 인쇄로 할 수 있으며 4원색 백분율에 의한 인쇄색상
                                규정을 지켜야 합니다.
                                <br />
                                또한 SW 미래채움의 색상 체계는 커뮤니케이션의 효율성을 높이기 위하여 적용 아이템에 따라 적절한 융통성을
                                가지고 있습니다.
                                <br />
                                따라서 보조색의 사용은 주색을 보조하는 차원에서 사용되어야 하며 사인 시스템, 각종 패찰, 간행물, 사보,
                                브로슈어, 광고물, 인테리어 등에 사용할 수 있습니다.
                            </p>
                        </Box>
                        <Flex justify="between">
                            <div>
                                <p className="sub-title">Main Color</p>
                                <Box className="swatch dark-blue">
                                    <strong className="sub-title">Dark Blue</strong>
                                    <span className="body3">
                                        R2 G67 B131
                                        <br />
                                        C98 M82 Y28 H0
                                    </span>
                                </Box>
                            </div>
                            <div>
                                <p className="sub-title">Sub Color</p>
                                <Flex justify="between" className="subcolor">
                                    <Box className="swatch sw-orange">
                                        <strong className="sub-title">SW Orange</strong>
                                        <span className="body3">
                                            R2 G67 B131
                                            <br />
                                            C98 M82 Y28 H0
                                        </span>
                                    </Box>
                                    <Box className="swatch sw-yellow">
                                        <strong className="sub-title">SW Yellow</strong>
                                        <span className="body3">
                                            R2 G67 B131
                                            <br />
                                            C98 M82 Y28 H0
                                        </span>
                                    </Box>
                                    <Box className="swatch sw-green">
                                        <strong className="sub-title">SW Green</strong>
                                        <span className="body3">
                                            R2 G67 B131
                                            <br />
                                            C98 M82 Y28 H0
                                        </span>
                                    </Box>
                                    <Box className="swatch sw-light-blue">
                                        <strong className="sub-title">SW Light Blue</strong>
                                        <span className="body3">
                                            R2 G67 B131
                                            <br />
                                            C98 M82 Y28 H0
                                        </span>
                                    </Box>
                                </Flex>
                            </div>
                        </Flex>
                    </Box>
                    <a href="https://nipa-hub.s3.ap-northeast-2.amazonaws.com/prod/swedu-ci.zip" download="SW미래채움 CI 파일" role="button" className="ci-btn">
                        <Text>CI 파일 다운로드</Text>
                        <img src={require('assets/images/icon/icon-download-w.svg').default} alt="CI 파일 다운로드" />
                    </a>
                </Container>
            </Section>
        </>
    );
}
