import React from "react";
import { Box, Container, Grid, Section, Text } from "@radix-ui/themes";
import Visual from "components/Visual";
import Breadcrumbs from "components/Breadcrumbs";
import PageTitle from "components/PageTitle";
import "./style.scss";

export default function PromotionSystem() {
  return (
    <>
      <Visual category="introduction" title="소개" />
      <Breadcrumbs category="introduction" />
      <Section p="0" mb="var(--space-35)" className="promotion-system-wrap">
        <Container size="4">
          <PageTitle icon="school" title="사업 추진체계" />

          <Box mb="var(--space-20)" className="system-box">
            <Text as="p" mb="var(--space-6)" className="title5">
              사업 추진체계
            </Text>
            <Text as="p" mb="var(--space-10)" className="body2">
              정보소외지역 SW교육지원강화사업의{" "}
              <span>
                <strong>정책 및 기본방향 수립</strong>
              </span>
              (과학기술정보통신부),{" "}
              <span>
                <strong>전담기관</strong>
              </span>
              (정보통신산업진흥원은 총괄 운영, 교육거점(SW미래채움센터))으로
              구성됨
            </Text>
            <img
              src={require("assets/images/pages/intro/intro-map.png")}
              alt="사업 추진체계 이미지"
            />
          </Box>

          <Box className="system-box">
            <Text as="p" mb="var(--space-6)" className="title5">
              추진 주체별 주요 역할
            </Text>
            <Grid columns="repeat(2, 1fr)" gap="var(--space-5)">
              {/* 1 */}
              <Box py="var(--space-7)" px="var(--space-7)" className="role-box">
                <Text as="p" mb="var(--space-2)" className="sub-title">
                  과학기술 정보통신부
                </Text>
                <ul className="dots">
                  <li className="body2">사업기획 및 시행계획 수립</li>
                  <li className="body2">신규과제 수행기관 확정</li>
                </ul>
              </Box>
              {/* 2 */}
              <Box py="var(--space-7)" px="var(--space-7)" className="role-box">
                <Text as="p" mb="var(--space-2)" className="sub-title">
                  정보통신 산업진흥원
                </Text>
                <ul className="dots">
                  <li className="body2">사업 세부추진계획 수립</li>
                  <li className="body2">
                    신규 및 결과평가 계획(안) 수립·시행
                    <br />- 외부 전문가로 구성된 평가위원회를 통해 평가 진행
                  </li>
                  <li className="body2">사업 진도 및 성과관리</li>
                </ul>
              </Box>
              {/* 3 */}
              <Box py="var(--space-7)" px="var(--space-7)" className="role-box">
                <Text as="p" mb="var(--space-2)" className="sub-title">
                  지방자치단체
                </Text>
                <ul className="dots">
                  <li className="body2">
                    지자체 內 SW교육환조성을 위한 지원(예산매칭 등)
                  </li>
                </ul>
              </Box>
              {/* 4 */}
              <Box py="var(--space-7)" px="var(--space-7)" className="role-box">
                <Text as="p" mb="var(--space-2)" className="sub-title">
                  수행기관
                </Text>
                <ul className="dots">
                  <li className="body2">
                    정보소외지역SW교육지원강화사업(SW미래채움) 추진
                    <br />
                    - SW교육 인프라 운영 및 관리 등<br />
                    - 지역 초‧중등학생 대상 방문SW교육(맞춤형‧찾아가는 SW교육
                    등) 수행
                    <br />- 지역 인재(미취업청년, 경력단절여성, 퇴직전문가 등)
                    대상 SW전문강사 양성 및 채용연계 등
                  </li>
                </ul>
              </Box>
            </Grid>
          </Box>
        </Container>
      </Section>
    </>
  );
}
