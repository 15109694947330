import React from "react";
import { Container, Flex } from "@radix-ui/themes";

import "./style.scss";
import { Link } from "react-router-dom";

/**
 * Renders the footer component for the application.
 *
 * @returns {JSX.Element} The footer component.
 */
export default function Footer() {
  return (
    <footer>
      <Container size="4">
        <section className="container text-wrap">
          <Flex align="center">
            <div className="policy">
              {/*<a href="/Terms" className="body1 bold" title="새창으로 이용약관으로 이동">이용약관</a>*/}
              <Link
                to={"/privacy"}
                className="body1 bold"
                title="개인정보처리방침으로 이동"
              >
                개인정보처리방침
              </Link>
            </div>
            <h2 className="logo">SW미래채움</h2>
          </Flex>
          <address className="body2">
            주소 : 서울특별시 마포구 월드컵북로 396, 누리꿈스퀘어 연구개발타워
            14F 지역SW융합비즈마루
          </address>
          <p className="body3 copyright">
            COPYRIGHT SW미래채움. ALL RIGHTS RESERVED.
          </p>
        </section>
      </Container>
    </footer>
  );
}
