import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Box, IconButton, Select, TextField, Tooltip } from "@radix-ui/themes";

export default function BoardSearchBox({ getSearchText }) {
  const [searchText, setSearchText] = useState("");
  const [selectedValue, setSelectedValue] = useState("title");

  const handleSearch = () => {
    getSearchText({ text: searchText, type: selectedValue });
  };
  return (
    <>
      <Box flexBasis="240px" flexShrink="0">
        <Select.Root
          defaultValue={selectedValue}
          onValueChange={(value) => setSelectedValue(value)}
        >
          <Select.Trigger placeholder="선택해 주세요." />
          <Select.Content position="popper" sideOffset="-0.5">
            <>
              <Select.Item value="title">제목</Select.Item>
              <Select.Item value="content">내용</Select.Item>
            </>
          </Select.Content>
        </Select.Root>
      </Box>
      <Box flexBasis="240px" flexShrink="0">
        <TextField.Root
          placeholder="검색어를 입력해 주세요."
          color="gray"
          title="검색어 입력 창"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") handleSearch();
          }}
        ></TextField.Root>
      </Box>
      <IconButton className="rt-variant-text" onClick={handleSearch}>
        {searchText.length > 0 || searchText.length === null ? (
          <MagnifyingGlassIcon width={36} height={36} />
        ) : (
          <Tooltip content="검색어가 입력이 안되었네요">
            <MagnifyingGlassIcon width={36} height={36} />
          </Tooltip>
        )}
      </IconButton>
    </>
  );
}
