import { createSlice } from "@reduxjs/toolkit";
import { local, localCode } from "constants";

const commonSlice = createSlice({
  name: "common",
  initialState: {
    userSelectedLocal: "전체",
    userSelectedLocalCode: "ALL",
  },

  reducers: {
    setUserSelectedLocal: (state, action) => {
      state.userSelectedLocal = action.payload;
      const index = local.indexOf(action.payload);
      state.userSelectedLocalCode = localCode[index] || "ALL";
    },
  },
});
export const { setUserSelectedLocal } = commonSlice.actions;
export const selectUserSelectedLocal = (state) =>
  state.common.userSelectedLocal;
export const selectUserSelectedLocalCode = (state) =>
  state.common.userSelectedLocalCode;

export default commonSlice;
