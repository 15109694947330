import React, { useRef, useState } from 'react';
import { Box, Button, Heading, Text } from '@radix-ui/themes';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import './style.scss';
import { Link } from 'react-router-dom';
import { local, localEngName } from 'constants';

export const MainSlider = ({ sliderList }) => {
    //--- 메인슬라이드 ---//
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const progressCircle = useRef(null);

    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
    };

    const [swiperRef, setSwiperRef] = useState(null);
    const [isRunning, setIsRunning] = useState(true);

    const play = () => {
        swiperRef.autoplay.start();
    };
    const pause = () => {
        swiperRef.autoplay.stop();
    };

    function isPause() {
        setIsRunning(!isRunning);
        if (!isRunning) {
            play();
        } else {
            pause();
        }
    }

    // 슬라이드 수가 3개 미만일 경우 슬라이드를 복제하여 슬라이드 수를 늘림
    const slides =
        sliderList?.data?.posts.length < 3
            ? [...sliderList.data.posts, ...sliderList.data.posts, ...sliderList.data.posts]
            : sliderList.data.posts;

    //console.log('sliderList?.data?.posts.length : ', sliderList?.data?.posts.length)
    return (
        <>
            <Box className="main-slider">
                <Swiper
                    slidesPerView={'auto'}
                    spaceBetween={36}
                    centeredSlides={true}
                    loop={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    pagination={{
                        type: 'fraction',
                        el: '.swiper-pagination',
                        formatFractionCurrent: function (number) {
                            return number;
                        }
                    }}
                    navigation={{
                        prevEl: prevRef.current, //스와이퍼 외부에 컨트롤러 설정하기
                        nextEl: nextRef.current
                    }}
                    onBeforeInit={swiper => {
                        // 초기화 전에 네비게이션 버튼을 swiper에 할당합니다.
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false
                    }}
                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                    onSwiper={setSwiperRef}
                >
                    {sliderList?.data.posts.map((item, index) => (
                        <SwiperSlide key={index} className={`banner ${localEngName[local.findIndex(local => local === item.region)]}`}>
                            <Link
                                className="text-wrap"
                                to={item.board === '교육정보' ? `/education/detail/${item.postNo}` : `/event/detail/${item.postNo}`}
                            >
                                <Text className="chips">{item.board}</Text>
                                <Heading as="h3" mt="var(--space-5)" className="title3 ellipsis">
                                    {item.title}
                                </Heading>
                                <Text as="p" className="body1">
                                    {new Date(item.startDate).getFullYear() > 2000
                                        ? `일정 : ${item.startDate ? new Date(item.startDate).toISOString().split('T')[0] : 'N/A'} ~ ${
                                              item.endDate ? new Date(item.endDate).toISOString().split('T')[0] : 'N/A'
                                          }`
                                        : ''}
                                </Text>
                            </Link>
                            <div className="image-wrap noData">{item.fileUrl && <img src={item.fileUrl} alt={item.fileAlt} />}</div>
                            <Text className={`chips local body1 ${localEngName[local.findIndex(local => local === item.region)]}`}>
                                {item.region}
                            </Text>
                        </SwiperSlide>
                    ))}

                    <div className="utility">
                        <Button className="slider-prev" ref={prevRef}>
                            Prev
                        </Button>
                        <div className="swiper-pagination"></div>
                        <div className="progress-wrap">
                            <div className="progress" slot="container-end">
                                <svg viewBox="0 0 33 32" ref={progressCircle}>
                                    <circle cx="16.5" cy="16" r="15" />
                                </svg>
                                <Button className={!isRunning ? 'play' : 'pause'} onClick={isPause}>
                                    {!isRunning ? '재생' : '정지'}
                                </Button>
                            </div>
                        </div>
                        <Button className="slider-next" ref={nextRef}>
                            Next
                        </Button>
                    </div>
                </Swiper>
            </Box>
        </>
    );
};

export default MainSlider;
